.first-box{
    background-image: url('https://apisparamedicalinstitute.com/assets/images/breadcrumbs/2.jpg');
    background-size: cover;
    background-position: center;
    height: 55vh; /* Set the height to ensure the background image is visible */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.first-box-title{
    display: flex;
    justify-content: center;
    align-items: center;
}