

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  scroll-behavior: smooth;
}

body {
  margin: 0;  
  font-family: 'Nunito', sans-serif;
}

body::-webkit-scrollbar{
  display: none;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
