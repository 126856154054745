ul li{
    list-style: none;
    padding: 10px;
    color: #626262;
}

ul{
    padding: 0px;
}

.courseBtn:hover{
    color: white;
    background-color: #273089;
}