/* swiper.css */
.swiper {
  width: 100%;
  height: 100%;
  position: relative; /* Make sure the swiper container is positioned relative */
}

.swiper-slide {
  width: 100%;
  height: 100%;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mySwiper .swiper-slide {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-content {
  padding: 50px;
  margin-left: 20px;
}

.swiper-text {
  font-size: 50px;
  color: white;
}

.swiper-title {
  font-size: 80px;
  color: white;
}

.swiper-button {
  background: black;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 15px;
  margin-top: 20px;
}

.swiper-button:hover{
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  transition: 0.5s;

}

/* Custom Swiper navigation buttons */
.swiper-button-next,
.swiper-button-prev {
  background-color: #273089; /* Background color */
  color: white; /* Arrow color */
  border-radius: 50%; /* Make it circular */
  width: 40px; /* Width of the button */
  height: 40px; /* Height of the button */
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0; /* Hide buttons initially */
  visibility: hidden; /* Ensure they don't take up space when hidden */
  transition: opacity 0.3s, visibility 0.3s; /* Smooth transition for visibility */
}

/* Adjusting the position */
.swiper-button-next {
  right: 10px; /* Adjust this as needed */
}

.swiper-button-prev {
  left: 10px; /* Adjust this as needed */
}

/* Ensuring the arrows are centered inside the circle */
.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 20px; /* Adjust the arrow size */
}

/* Show buttons on hover */
.swiper:hover .swiper-button-next,
.swiper:hover .swiper-button-prev {
  opacity: 1; /* Show buttons on hover */
  visibility: visible; /* Make buttons visible */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  
  .swiper-content {
    padding: 20px;
    margin-left: 10px;
  }

  .swiper-text {
    font-size: 18px;
  }

  .swiper-title {
    font-size: 36px;
  }

  .swiper-button {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .swiper-content {
    padding: 10px;
    margin-left: 5px;
  }

  .swiper-text {
    font-size: 24px;
  }

  .swiper-title {
    font-size: 30px;
    font-weight: bold;
  }

  .swiper-button {
    padding: 8px;
  }



  .swiper-button-next,
  .swiper-button-prev {
    background-color: #273089; /* Background color */
    color: white; /* Arrow color */
    border-radius: 50%; /* Make it circular */
    width: 40px; /* Width of the button */
    height: 40px; /* Height of the button */
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0; /* Hide buttons initially */
    visibility: hidden; /* Ensure they don't take up space when hidden */
    transition: opacity 0.3s, visibility 0.3s; /* Smooth transition for visibility */
  }


}
