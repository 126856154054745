.grid-item {
    position: relative;
    background-size: cover;
    background-position: center;
    height: 200px;
}

.grid-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

/* First and third grid item overlay */
.grid-item.bg-overlay-1::before {
    background-color:rgba(39, 48, 137, 0.9)
    /* #B3B842 with 80% opacity */
}

/* Second and fourth grid item overlay */
.grid-item.bg-overlay-2::before {
    background-color:rgba(137, 128, 39, 0.9)
    /* #736F6F with 80% opacity */
}

.grid-item-content {
    position: relative;
    z-index: 2;
    /* Add any other styling for the content inside the grid item */
}

.grids-main{
    margin-right:0px !important
}
