.container-fluid {
    padding: 0; /* Remove padding from the container */
  }
  
  .row.no-gutters {
    margin-right: 0;
    margin-left: 0;
  }
  
  .image-container {
    background-image: url('https://apisparamedicalinstitute.com/assets/images/new/1.jpg');
    background-size: cover;
    background-position: center;
    height: 80vh; /* Set the height to ensure the background image is visible */
    width: 100%; /* Ensure the image container takes full width */
  }
  

  
@media (max-width: 767px) {
  .hy {
  width: 100%;
  }
}