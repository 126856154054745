.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px;
  background-color:#ccc;  /* Change this to your preferred background color *//* Optional: for a top border */
}

.footer-left,
.footer-center,
.footer-right {
  display: flex;
  align-items: center;
}

.footer-left {
  flex: 1;
}

.footer-center {
  flex: 1;
  justify-content: center;
  text-align: center;
}

.footer-right {
  flex: 1;
  justify-content: flex-end;
  gap: 10px;
}

.footer-logo {
  height: 40px; /* Adjust as needed */
  margin-right: 10px;
}

.footer-title {
  font-size: 18px;
  font-weight: bold;
}

.footer-icon {
  height: 30px; /* Adjust as needed */
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-left,
  .footer-center,
  .footer-right {
    flex: none;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px; /* Space between elements */
  }

  .footer-logo {
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .footer-center {
    margin-bottom: 10px;
  }

  .footer-right {
    justify-content: center;
  }
}
