.navbar {
  background-color: white;
  padding: 10px;
  border-bottom: 1px solid #ddd; /* Optional: Add a border at the bottom */
}

.navbar-brand img {
  height: 60px;
}


.navbar-nav .nav-link {
  color: rgb(88, 85, 85) !important;
  font-weight: bold;
  transition: color 0.3s ease;
  margin: 0 10px; /* Adjust margin for better spacing */
}

.navbar-nav .nav-link:hover {
  color: #273089 !important;
}

.navbar-nav .nav-link.active {
  color: #273089 !important;
}

.navbar-nav .dropdown-menu .dropdown-item {
  color: rgb(52, 51, 51) !important;
  font-weight: bold;
}

.navbar-nav .dropdown-menu .dropdown-item:hover {
  color: #273089 !important;
}

.dropdown-menu {
  background: #fff;
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
}

@media (max-width: 767px) {
  .navbar-nav {
    text-align: center;
  }

  .navbar-nav .nav-link,
  .navbar-nav .dropdown-menu .dropdown-item {
    font-size: 1rem;
  }

  .navbar-collapse {
    justify-content: flex-start !important;
  }
}


.navbar-expand-lg .navbar-nav .nav-link{
  padding-right: 0 !important;
}