.course-card {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
     /* optional: to match the rounded corners */
  }

  .course-card.bg-overlay-2::before {
    background-color:rgba(137, 128, 39, 0.9)
    /* #736F6F with 80% opacity */
}
  
  .images-container {
    position: relative;
  }
  
  .image-container img {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .initial-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;/* Adjust the background color and opacity as needed */
    color: white;
    text-align: center;
    padding: 20px;
    transition: opacity 0.5s ease;
    background: linear-gradient(to top, rgb(144, 190, 217) 0%, rgba(220, 241, 253, 0) 100%);

  }
  
  .initial-overlay h4 {
    font-family: 'Nunito', sans-serif;
    font-size: 1.5rem;
    margin: 0;
  }
  
  .hover-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:rgba(220, 241, 253, 0.9);
    color:rgb(88, 85, 85);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 0;
    transition: opacity 0.8s ease;
    padding: 15px;
  }
  
  .course-card:hover .hover-overlay {
    opacity: 1;
  }
  
  .course-card:hover .initial-overlay {
    opacity: 0;
  }
  
  .hover-overlay h4 {
    font-family: 'Nunito', sans-serif;
    font-size: 1.5rem;
    margin: 0 0 10px;
  }
  
  .hover-overlay p {
    font-family: 'Nunito', sans-serif;
    font-size: 1rem;
    margin: 0 0 20px;
  }
  
  .read-more {
    font-family: 'Nunito', sans-serif;
    font-size: 1rem;
    color:rgb(88, 85, 85);
    text-decoration: none;
    border: 2px solid rgb(88, 85, 85);;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .read-more:hover {
    background-color: white;
    color: #273089; /* Adjust to match the overlay background color */
  }


  .cta {
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .cta span {
    padding-bottom: 7px;
    letter-spacing: 4px;
    font-size: 14px;
    padding-right: 15px;
    text-transform: uppercase;
  }
  
  .cta svg {
    transform: translateX(-8px);
    transition: all 0.3s ease;
  }
  
  .cta:hover svg {
    transform: translateX(0);
  }
  
  .cta:active svg {
    transform: scale(0.9);
  }
  
  .hover-underline-animation {
    position: relative;
    color: black;
    padding-bottom: 20px;
  }
  
  .hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #000000;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .cta:hover .hover-underline-animation:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  
  