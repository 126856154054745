.form-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
  }
  
  .form-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  button[type="submit"] {
    width: 100%;
    padding: 15px;
    background-color: #273089;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: black;
  }
  
  .contact-info {
    text-align: center;
    margin-top: 20px;
  }
  
  .contact-info a {
    color:#273089;
    font-weight: bold;
    text-decoration: none;
  }

  .contact-info a:hover{
           color: red;
  }
  