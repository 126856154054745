.main-div{
    padding: 60px;
}

.readmore-btn{
    background-color: black;
    padding: 5px;
    color:white ;
    border: none;
    height: 40px;
    width: 100px;
    border-radius: 3px;
}

.counter-box{
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.second-main{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.couter-box {
    display: flex;
    justify-content: space-between;  /* Ensure space between the items */
}

.couter-box .flex-item {
    flex: 1;  /* Ensure all items take equal space */
    margin: 0 10px;  /* Add space between items */
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

/* Adjust the margin for the first and last item */
.couter-box .flex-item:first-child {
    margin-left: 0;
}

.couter-box .flex-item:last-child {
    margin-right: 0;
}

.welcome-box:hover{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    transition: 0.5s;
}


/* Media Query for Mobile Devices */
@media (max-width: 768px) {
   
    .imgGrid{
        width: 100%;
        height: 20rem;
    }
    .img-div{
        padding: 0 !important;
    }
}